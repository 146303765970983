import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { FavoriteItem, SettingsFavorite } from '../../../domain/favorites';
import { LoadingState } from '../../../domain/schemas';

import { selectDashboardFavoriteAction } from '../../../redux/ui/favorite';
import { pathToFavoritePageName } from '../../../utils/pathToFavoritePageName';
import { LoadingPage } from '../../pages/LoadingPage';

type Props = {
  favId: number;
  settingsFavorite: SettingsFavorite | undefined;
};

export const Favorite: FC<Props> = (props) => {
  const { favId, settingsFavorite } = props;

  const [loadingState, setLoadingState] = useState<LoadingState>('prepare');

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const applyFavorite = useCallback(
    (favorites: FavoriteItem[], favId: number) => {
      if (!favorites.some((fav) => fav.id === favId)) {
        throw new Error(
          '共有されていない、あるいは存在しないお気に入りが指定されました。お気に入りの共有設定をご確認ください。'
        );
      }

      const pageNameFromPath = pathToFavoritePageName(pathname);
      const pageNameFromFav = favorites.find((fav) => fav.id === favId)
        ?.pageName;

      if (pageNameFromFav == null) {
        throw new Error('お気に入りに登録されているページが見つかりません。');
      }

      if (pageNameFromPath !== pageNameFromFav) {
        throw new Error(
          'お気に入りに登録されているページと現ページが異なります。'
        );
      }

      dispatch(selectDashboardFavoriteAction(Number(favId), pageNameFromPath));
      setLoadingState('loaded');
    },
    [dispatch, pathname]
  );

  useEffect(() => {
    if (settingsFavorite?.favorites == null) {
      return;
    }

    setLoadingState('loading');
    applyFavorite(settingsFavorite.favorites, favId);
  }, [applyFavorite, favId, settingsFavorite?.favorites]);

  if (loadingState === 'prepare' || loadingState === 'loading') {
    return <LoadingPage />;
  }

  return <Outlet />;
};
