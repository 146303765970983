// Action Types
import { FavoritePageSetting } from '../../domain/favorites';

const SELECT_DASHBOARD_FAVORITE = 'SELECT_DASHBOARD_FAVORITE' as const;
const SELECT_PAGE_SETTING = 'SELECT_PAGE_SETTING' as const;
const SELECT_PAGE_SETTING_WITH_ORIGINAL_HASH = 'SELECT_PAGE_SETTING_WITH_ORIGINAL_HASH' as const;

export const DashboardActionTypes = {
  SELECT_DASHBOARD_FAVORITE,
  SELECT_PAGE_SETTING,
  SELECT_PAGE_SETTING_WITH_ORIGINAL_HASH,
};

// Action Creators

export function selectDashboardFavoriteAction(
  favoriteId: number,
  pageName: string
) {
  return {
    type: SELECT_DASHBOARD_FAVORITE,
    payload: { favoriteId, pageName },
  };
}

export function selectPageSettingAction(payload: {
  pageName: string;
  pageSetting: FavoritePageSetting;
  createdAt: string;
}) {
  return {
    type: SELECT_PAGE_SETTING,
    payload,
  };
}

export function selectPageSettingWithOriginalHashAction(payload: {
  shortenedHash: string;
  pageName: string;
}) {
  return {
    type: SELECT_PAGE_SETTING_WITH_ORIGINAL_HASH,
    payload,
  };
}

export const DashboardActionCreators = {
  selectDashboardFavoriteAction,
  selectPageSettingAction,
  selectPageSettingWithOriginalHashAction,
};

// Actions

export type SelectDashboardFavoriteAction = ReturnType<
  typeof selectDashboardFavoriteAction
>;
export type SelectPageSettingAction = ReturnType<
  typeof selectPageSettingAction
>;
export type SelectPageSettingWithOriginalHashAction = ReturnType<
  typeof selectPageSettingWithOriginalHashAction
>;

type DashboardAction =
  | SelectDashboardFavoriteAction
  | SelectPageSettingAction
  | SelectPageSettingWithOriginalHashAction;

// State

type DashboardState = unknown;

const initialState: DashboardState = {};

// Reducer

export function dashboardReducer(
  state = initialState,
  action: DashboardAction
): DashboardState {
  switch (action.type) {
    default:
      return state;
  }
}
